<template>
  <div>
    <h1>Faculté</h1>
    <el-row style="width: 820px" v-loading="faculteLoadStatus == 1">
      <el-col :span="24">
        <form-faculte ref="formFac" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFaculte from "@/components/rh/ui/form/FormFaculte";
import { strNormalize } from "@/fonctions";

export default {
  components: { "form-faculte": FormFaculte },

  data() {
    return {};
  },
  props: {
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("fac/loadFaculte", this.idFac);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idFac() {
      // récupère le id le la facultée dans l'url
      return String(this.$route.params.idfac);
    },
    faculteLoadStatus() {
      return this.$store.state.fac.faculteLoadStatus;
    },
    faculteUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fac.faculteUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.faculteLoadStatus == 2) {
        // charge les données de la faculté
        const fac = this.$store.state.fac.faculte;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.facLoaded = JSON.stringify(fac);
      } else if (this.faculteLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formFac.$refs.formFaculte.validate((valid) => {
        if (valid) {
          let facdata = this.$store.state.fac.faculte;

          // enlever les espaces avant et après dans le nom de la nouvelle fac
          facdata.fac = facdata.fac.trim();

          // vérifie si la faculté est  déjà dans la bd
          const FacsInBD = this.$store.getters["fac/facultesInBD"];
          const doublon =
            FacsInBD.filter(
              (m) => m[0] != this.idFac && m[2] == strNormalize(facdata.fac)
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modifcation et pour l'enregistrement
          const facdataStr = JSON.stringify(facdata);

          // vérifie si les données ont été modifiées
          if (localStorage.facLoaded == facdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si la faculté existe déjà dans la base de donnée
          } else if (doublon) {
            this.$alert(
              "Cette faculté est déjà enregistrée dans la base de données",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("fac/updateFaculte", facdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const facStr = JSON.stringify(this.$store.state.fac.faculte);
      if (localStorage.facLoaded != facStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.facLoaded);
        this.$store.commit("fac/setFaculte", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modifcation
      const fac = this.$store.state.fac.faculte;
      const facStr = JSON.stringify(fac);

      if (localStorage.facLoaded == facStr) {
        // si pas de modifications
        // retourne à la liste des formations post-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des facultés
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    faculteLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    faculteUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.faculteUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.facLoaded = JSON.stringify(this.$store.state.fac.faculte);
      } else if (this.faculteUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
