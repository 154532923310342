<template>
  <el-main class="rh-form">
    <form-faculteedit :routetolist="{ name: 'facultes' }" />
  </el-main>
</template>
<script>
import FormFaculteedit from "@/components/rh/formulaires/FormFaculteedit";

export default {
  components: { "form-faculteedit": FormFaculteedit },
};
</script>
